import { ReactElement, useCallback } from 'react';
import Link from 'next/link';
import { useGetCollection } from '@firebase/hooks';
import PageWidth from 'styles/components/PageWidth';
import { Title } from 'styles/components/Title';
import ProductCard from 'components/ProductCard/ProductCard';
import { PrimaryButton } from 'styles/components/Button';
import getRating from 'utils/get-rating';

const HomeCollection = ({ collection }: HomeCollectionProps): ReactElement => {
  const { data: reviews } = useGetCollection('reviews');

  const getRatingAction = useCallback(
    (productId: string) => getRating(reviews, productId),
    [reviews],
  );

  if (!collection) {
    return null;
  }

  return (
    <PageWidth>
      <div className="flex flex-col items-center mb-6 md:w-3/5 m-auto">
        <Title className="mt-0">{collection.title}</Title>

        <div
          className="prose dark:prose-dark text-center"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: collection.descriptionHtml,
          }}
        />
      </div>

      <div className="grid grid-cols-2 md:grid-cols-3 gap-5 auto-rows-auto items-stretch">
        {collection.products.map(product => (
          <ProductCard
            key={product.handle}
            product={product}
            rating={getRatingAction(product.id)}
          />
        ))}
      </div>

      <Link href={`collection/${collection.handle}`}>
        <PrimaryButton>See all</PrimaryButton>
      </Link>
    </PageWidth>
  );
};

type HomeCollectionProps = {
  collection: Collection;
};

export default HomeCollection;
